<script>
import {login} from '@/request/api/login'
import { mapMutations } from 'vuex';
export default {
    data(){
        return {
            checked:false,//记住密码
            loading:false,
            form:{
                username:'',
                password:'',
            },
            rules:{
                username:[
                    { required: true, message: '用户名必填', trigger: 'blur' },
                ],
                password:[
                    { required: true, message: '密码必填', trigger: 'blur' },
                ],
            }
        }
    },
    created(){},
    methods:{
        ...mapMutations({'vuexLogin':'login'}),
        //
        handleSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.req()
                } else {
                    return false;
                }
            });
            
        },
        async req(){
            this.loading = true
            let {code,msg,data} = await login(this.form)
            this.loading = false
            if(code!=0) return this.$message.error(msg)
            const storeData = {token:data.token,username:data.user.username,id:data.user.id};
            this.vuexLogin(storeData)
            window.location.href='/'
            // setInterval(()=>{
            //     window.location.href='/'
            // },500)
            this.$notify({title: '成功',message: msg,type: 'success'});
        }
    }
}
</script>
<template>
    <!-- <div class="max-login d-flex a-center j-center flex-column">
        <div class="login">
            <div class="title">{{ $win.mconfig.name }}</div>
            <div class="login-title d-flex a-center j-center">账号登录</div>
            <div class="outInput">
                <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input v-model="form.password" placeholder="请输入密码" show-password></el-input>
                    </el-form-item>
                    <div style="margin-top: 10px; margin-left: 80px;">
                        <el-checkbox v-model="checked">记住密码</el-checkbox>
                    </div>
                    <div style="margin-top: 10%;">
                        <el-button @click="handleSubmit" class="my_submit" type="primary" round :loading="loading">登录</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div> -->
    <div class="fullpage d-flex">
        <div class="f-l hidden" style="width: 33%;">
            <div style="color: #fff;position: relative;z-index: 99; text-align: center;margin: 50px 0;">
                <h2 style="font-size: 38px;">{{$win.mconfig.name}}</h2>
                <!-- <div style="margin-top: 15px; color: #c8c8c8;">{{ $win.mconfig.welcome }}</div> -->
            </div>
            <!-- <div class="mark"></div> -->
            <div class="bottom"></div>
        </div>
        <div class="f-r">
            <div class="login">
                <div class="login-title d-flex a-center j-center">账号登录</div>
                <div class="outInput">
                    <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                        <el-form-item label="用户名" prop="username">
                            <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input v-model="form.password" placeholder="请输入密码" show-password></el-input>
                        </el-form-item>
                        <div style="margin-top: 10px; margin-left: 80px;">
                            <el-checkbox v-model="checked">记住密码</el-checkbox>
                        </div>
                        <div style="margin-top: 10%;">
                            <el-button @click="handleSubmit" class="my_submit" type="primary" round :loading="loading">登录</el-button>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.fullpage {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    .f-l {
        background:#555 url(../assets/imgs/auth_banner.png) no-repeat center;
        background-size: cover;
        position: relative;
        .mark {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, .5);
            z-index: 1;
        }
        .bottom {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            color: #fff;
            padding: 40px;
            background-image: linear-gradient(transparent, #000);
            z-index: 3;
        }
    }
}
// .max-login {
//     position: fixed;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     background: url(../assets/imgs/login_bg.png) no-repeat center;
//     background-size: cover;
//     .title {
//         color: #0076FE;
//         font-size: 70px;
//         position: absolute;
//         left: 0;
//         bottom: 120%;
//         width: 100%;
//         text-align: center;
//         font-weight: bolder;
//         letter-spacing: 20px;
//     }
//     .login {
//         position: relative;
//         width: 620px;
//         height: 405px;
//         background: url(../assets/imgs/login_box.png) no-repeat center;
//         background-size:100% 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         .login-title {
//             position: absolute;
//             left: 0;
//             top: 0;
//             width: 100%;
//             color: #ffffff;
//             text-align: center;
//             height: 13%;
//             font-size: 22px;
//         }
//         .outInput {
//             height: 45%;
//             width: 80%;
//             // background-color: #699;
//         }
//     }
// }

.f-r {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .login {
        position: relative;
        width: 620px;
        height: 405px;
        display: flex;
        align-items: center;
        justify-content: center;
        .login-title {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            // color: #ffffff;
            text-align: center;
            height: 13%;
            font-size: 22px;
        }
        .outInput {
            height: 45%;
            width: 80%;
            // background-color: #699;
        }
    }
}
.my_submit {
    display: block;margin: auto;background-color: #3A83EA;color: #ffffff;padding-right: 48px;;padding-left: 48px;font-size: 16px;
}

.hidden {display: block;}
@media screen and(max-width:1100px) {
    .hidden {display: none;}
}

</style>